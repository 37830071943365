import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import "../theme/main.less"

const KontaktPage = () => {
  return (
    <>
      <HelmetSlot
        title="Über uns"
        description="Hier gibt es mehr Informationen rund um das Team und den Betrieb."
      />
      <main>
        <body className="ueberuns">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Über uns</h1>
              <div className="content">
                <div className="text">
                  <p>
                    Seit unserer Gründung am 20.05.2015 kümmern wir uns um die
                    Belange unserer Kunden. Der Inhaber ist Alexander Gast - als
                    Installateurmeister, sowie freier Sachverständiger für Gas,
                    Wasser und Installationen, steht er für die Qualität unserer
                    Arbeit. Im Laufe der Jahre hat sich für jeden Bereich ein
                    Experte in unserem Team gefunden, sodass sie für jedes ihrer
                    Anliegen bestens beraten sind. Durch unser (relativ) junges
                    Team sind wir modern aufgestellt und bieten auch Lösungen zu
                    neueren Themen (z.B. Smarthome) an. Unser Kundenklientel
                    beschränkt sich nicht nur auf Privatkunden, sondern umfasst
                    auch öffentliche Auftraggeber, Vereine und Firmen. Im
                    Umkreis von 50km bieten wir unsere Dienste an, sind aber
                    auch gerne bereit längere Wege auf uns zu nehmen.
                    <br />
                    <br />
                    Unser Betrieb ist im Handelsregister als Meisterbetrieb
                    aufgeführt.
                    <br />
                    Für unsere Kunden bieten wir auch außerhalb unserer
                    Öffnungszeiten Reparaturen im Notfall an.
                  </p>
                </div>
                <Contact />
              </div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default KontaktPage
